import React from "react";
import styled from "styled-components";

const Img= styled.img`
content: url("Android Large - 65.png");
width: 100%;
`;

const Second = () => {
    return (
        <div>
            <Img></Img>
        </div>
    );
};

export default Second;